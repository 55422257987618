.next_Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 88px;
    .previous {
        height: 22px;
        font-weight: normal;
        font-size: 19px;
        color: #333333;
        padding: 14px 0;
        border-bottom: 1px dashed #e6e2e2;
        cursor: pointer;
    }

    .next {
        height: 22px;
        font-weight: normal;
        font-size: 19px;
        color: #666666;
        padding: 14px 0;
        cursor: pointer;
    }
    .next_border {
        border-bottom: 1px dashed #e6e2e2;
    }
}