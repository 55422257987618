.illustrations {
    height: 751px;
    margin-bottom: 140px;
}

.news_wrap {
    padding: 0 200px 0;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    box-sizing: border-box;
    margin-bottom: 60px;

    .news_index {
        width: 100%;
        height: 480px;
        background: #FFFFFF;
        box-shadow: 0px 0px 14px 2px rgba(162, 202, 255, 0.39);
        border-radius: 10px;
        padding: 18px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.3s;
        border: 1px solid transparent;
        &:hover {
            transform: scale(1.01);
            border: 1px solid #006EFF;
        }
        &:hover .new_profile {
            border-color: #006EFF;
            .new_date {
                border: 2px solid #006EFF;
            }
            .new_year {
                background: #006EFF !important;
            }
            .new_day {
                color: #006EFF !important;
            }
        }
        .new_img {
            height: 320px;
            margin-bottom: 30px;
        }

        .new_profile {
            height: 89px;
            display: flex;
            flex-shrink: 0;
            .new_date {
                width: 94px;
                height: 100%;
                border-radius: 5px;
                border: 2px solid #868686;
                margin-right: 30px;

                .new_year {
                    height: 50%;
                    background: #868686;
                    color: #fff;
                    font-size: 27px;
                    line-height: 45px;
                    text-align: center;
                }

                .new_day {
                    font-weight: normal;
                    font-size: 23px;
                    // color: #006EFF;
                    color: #868686;
                    text-align: center;
                    line-height: 45px;
                }
            }

            .new_text {
                width: calc(100% - 124px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                line-height: 30px;

                .news_title {
                    width: 100%;
                    font-weight: normal;
                    font-size: 26px;
                    // color: #006EFF;
                    color: #353535;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .news_text {
                    font-weight: normal;
                    font-size: 18px;
                    color: #868686;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* 显示的行数，可以根据需要修改 */
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .profile_ash {
            .new_date {
                border: 2px solid #868686;
                .new_year {
                    background: #868686;
                }
                .new_day{
                    color: #868686;
                }
            }
            .new_text .news_title {
                color: #353535;
            }
        }
    }

}

.pagination {
    width: fit-content;
    margin: auto;
    margin-bottom: 100px;

    :global .ant-pagination-item {
        // border: 1px solid red !important;
        border-radius: 50%;
        border: 1px solid #D6DEF0;
    }
    :global .ant-pagination-item-active {
        background: #006EFF;
        a {
            color: #fff;
        }
    }
}