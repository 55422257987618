.illustration {
    height: 746px;
    background: url('../../../public/img/chip_top.jpg') no-repeat;
    background-size: 100% 102%;
    background-position: center center;
    position: relative;

    .free {
        width: 205px;
        height: 60px;
        border-radius: 30px;
        font-weight: normal;
        font-size: 20px;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
        color: #FFFFFF;
        background: linear-gradient(-3deg, #006CFF 0%, #00A2FF 77%, #00A5FA 100%);
        position:absolute;
        bottom: 100px;
        left: 18%;
    }
}

.chip_products {
    padding: 17px 83px 17px 83px;
    box-sizing: border-box;
    background: #F5F6F8;
    display: flex;


    .navigate_wrap {
        width: 230px;
        height: 400px;
        position: sticky;
        top: 100px;
        left: 0;
        margin-right: 10px;
        flex-shrink: 0;
        box-shadow: 0px 2px 5px 1px rgba(199, 199, 199, 0.17);
        background: #fff;
        border-radius: 50px 5px 5px;
        padding-top: 100px;
        box-sizing: border-box;
        padding: 100px 20px 0;
        box-sizing: border-box;

        :global .ant-anchor-ink {
            height: 30px !important;
        }

        :global .ant-anchor-link {
            padding: 15px 0;
        }

        :global .ant-anchor::before {
            display: none;
        }

        :global .ant-anchor-link-title {
            font-weight: normal;
            font-size: 25px;
            color: #000000;
            text-align: center;
        }

        .nav_background {
            width: 235px;
            height: 95px;
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .chip_products_inner {
        width: calc(100% - 240px);
        height: 100%;
        margin-bottom: 25px;



        .chip_gather {

            padding: 75px;
            box-sizing: border-box;
            background: #fff;
            box-shadow: 0px 2px 5px 1px rgba(199, 199, 199, 0.17);
            border-radius: 5px;
            margin-bottom: 33px;

            .chip_text {
                font-weight: normal;
                font-size: 28px;
                color: #444444;
            }

            .chip_ul {
                display: flex;
                flex-wrap: wrap;
                margin-left: 22px;
                .chip_index {
                    width: 50%;
                }
            }

            // 点
            .point {
                padding-left: 30px;
                box-sizing: border-box;
                position: relative;
                margin-bottom: 15px;

                &::before {
                    content: "";
                    width: 15px;
                    height: 15px;
                    background: #1777FF;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &::after {
                    content: "";
                    width: 22px;
                    height: 22px;
                    background: #1777FF;
                    border-radius: 50%;
                    position: absolute;
                    left: -3px;
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0.18;
                }
            }
        }
    }
}
.file_table_index{
    // height: 55px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}