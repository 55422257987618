.introduce {

    .illustrations {
        height: 750px;
        position: relative;

        .illustrations_text {
            font-weight: normal;
            font-size: 86px;
            color: #323232;
            line-height: 96px;
            position: absolute;
            top: 254px;
            left: 18%;
            line-height: 96px;
        }

        .illustrations_btn {
            height: 60px;
            position: absolute;
            bottom: 100px;
            left: 18%;
            display: flex;

            .btn() {
                width: 205px;
                height: 100%;
                border-radius: 30px;
                font-weight: normal;
                font-size: 20px;
                text-align: center;
                line-height: 60px;
                cursor: pointer;
            }

            .free {
                .btn();
                color: #FFFFFF;
                background: linear-gradient(-3deg, #006CFF 0%, #00A2FF 77%, #00A5FA 100%);
            }

            .video_btn {
                .btn();
                // background: linear-gradient(-36deg, #0055E3, #0B86C6);
                border-radius: 30px;
                border: 2px solid #0055E3;
                color: #353535;
                margin-left: 27px;
            }
        }
    }

    // 产品概要
    .product_introduction {
        height: 600px;
        padding: 0 307px;
        box-sizing: border-box;

        .product_text {
            font-weight: normal;
            font-size: 24px;
            color: #333333;
            letter-spacing: 1px;
            line-height: 40px;
            margin-bottom: 43px;
            text-align: center;
        }

        .product_img {
            height: 382px;
            padding: 0 80px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: nowrap;

            .product_left {
                width: 30%;
                height: 100%;

            }

            .product_right {
                width: 70%;
                height: 100%;
                margin-left: 5px;
                display: none;
            }

            .product_move {
                display: block;
                animation: gradient 1s linear;
            }
        }
    }

    // 建设形态
    .construction_wrap {
        background: #F8FAFF;
        position: relative;

        .construction_title_wrap {
            width: 100%;
            text-align: center;
            padding-top: 70px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 100;


            .title {
                font-weight: normal;
                font-size: 40px;
                color: #000000;
                margin-bottom: 33px;
            }

            .profile {
                font-weight: normal;
                font-size: 24px;
                color: #000000;
            }
        }

        .construction_illustration {
            height: 1450px;
            padding: 0 161px 0 82px;
            box-sizing: border-box;
            position: relative;

            .introduction_asemap {
                width: 100%;
                height: 100%;
            }

            .base_box() {
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0px 1px 10px 0px rgba(170, 196, 250, 0.69);
                position: absolute;
            }

            .machine_room {
                width: 300px;
                height: 160px;
                bottom: 700px;
                left: 180px;
                .base_box();
                display: none;
            }

            .machine_move {
                display: block;
                animation: machineRoomMove 1s linear;
            }

            .area_icon {
                width: 300px;
                height: 128px;
                top: 280px;
                left: 310px;
                .base_box();
                display: none;
            }

            .area_move {
                display: block;
                animation: machineRoomMove 1s linear;
            }

            .projector_icon {
                width: 300px;
                height: 128px;
                position: absolute;
                top: 230px;
                right: 450px;
                .base_box();
                display: none;
            }

            .practice_icon {
                width: 300px;
                height: 280px;
                position: absolute;
                right: 300px;
                top: 370px;
                .base_box();
                display: none;
            }

            .projector_move {
                display: block;
                animation: gradient 1s linear;

            }

            .hardware_img {
                width: 360px;
                height: 302px;
                position: absolute;
                bottom: 380px;
                right: 400px;
                .base_box();
            }

            .hardware_move {
                animation: machineRoomMove 1s linear;
            }
        }
    }

    // 实践系统介绍
    .practice_introduction {
        // 旧代码
        // height: 700px;
        // background-color: #0078FF;
        // padding-top: 50px;
        // box-sizing: border-box;
        // display: flex;
        // flex-direction: column;

        height: 800px;
        background-color: #0078FF;
        padding-top: 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .practice_title_wrap {
            height: 80px;
            text-align: center;
            font-weight: normal;
            line-height: 45px;
            position: relative;
            margin-bottom: 30px;
            flex-shrink: 0;

            .title_inner {
                width: 60%;
                display: flex;
                justify-content: space-between;
                position: absolute;
                right: 0;
                padding-right: 150px;
                box-sizing: border-box;


                .title {
                    font-size: 40px;
                    color: #FFFFFF;
                    white-space: nowrap;
                }

                .title_small {
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 27px;
                }

                .arrow_wrap {
                    width: 150px;
                    height: 80px;
                    margin-left: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 20px;
                    color: #fff;


                    .arrow_index {
                        width: 45px;
                        height: 45px;
                        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.59);
                        border-radius: 50%;
                        border: 2px solid #FFFFFF;
                        cursor: pointer;

                        &:hover {
                            background: #fff;
                            transition: all 0.3s;

                            .arrow_icon {
                                color: #0078FF;
                            }
                        }
                    }

                }
            }
        }

        .practice_content {
            flex: 1;
            position: relative;
            padding: 0 320px;
            box-sizing: border-box;

            .arrow_box {
                width: 53px;
                height: 53px;
                position: absolute;
                border-radius: 50%;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                color: #0078FF;
                user-select: none;
            }

            .hover_box {
                background: #087DFF;
                color: #FFFFFF;
                border: 2px solid #FFFFFF;
                cursor: pointer;
                transition: all 0.5s;
            }

            .arrow_left {
                .arrow_box();
                left: 100px;
                background: #FFFFFE;
                border: 2px solid #F1F2F7;
                &:hover {
                    .hover_box();
                }
            }

            .arrow_right {
                .arrow_box();
                right: 100px;
                background: #FFFFFE;
                border: 2px solid #F1F2F7;

                &:hover {
                    .hover_box();
                }
            }


            // .marquee {
            //     width: 100%;
            //     height: 686px;
            //     position: absolute;
            //     right: 0;
            //     top: 0;
            //     display: flex;
            //     overflow: hidden;
            //     padding-left: 5px;
            //     box-sizing: border-box;

            //     .marquee_inner_wrap {
            //         width: 3000px;
            //         height: 100%;
            //         position: absolute;
            //         // left: 0;
            //         left: 50%;
            //         transform: translateX(-50%);
            //         display: flex;
            //         transition: all 0.8s;
            //         background: transparent;
            //         padding-left: 3px;
            //         box-sizing: border-box;

            //         border: 1px solid red;

            //         .marquee_index {
            //             width: 1220px;
            //             height: 665px;
            //             background: #fff;
            //             margin-right: 100px;
            //             flex-shrink: 0;
            //             padding: 17px;
            //             box-sizing: border-box;
            //             border-radius: 5px;
            //             box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
            //             .marquee_inner {
            //                 width: 100%;
            //                 height: 100%;
            //                 background: #F7F7F7;
            //             }
            //         }
            //     }
            // }
        }
    }

    // 算力平台
    .Computing_platform {
        // height: 1800px;
        padding-top: 250px;
        box-sizing: border-box;


        .Computing_title {
            font-weight: normal;
            font-size: 40px;
            color: #000000;
            text-align: center;
            margin-bottom: 22px;
        }

        .Computing_tips {
            font-weight: normal;
            font-size: 24px;
            color: #000000;
            text-align: center;
        }

        .Computing_wrap {
            height: 1700px;
            padding-top: 620px;
            box-sizing: border-box;
            position: relative;

            .computing_body {
                width: 70%;
                height: 840px;
                position: absolute;
                top: 15px;
                left: 47%;
                transform: translateX(-50%);

                .box() {
                    padding: 20px;
                    background: rgba(76, 161, 254, 0.2);
                    border-radius: 50%;
                    position: absolute;
                    cursor: pointer;
                    animation: integratedMachine 2s infinite linear alternate;
                }

                .box_border() {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    padding: 5px;
                    border-radius: 50%;
                    background: rgba(76, 161, 254, 0.1);
                }

                .easy_upload {
                    left: 370px;
                    top: 280px;
                    .box();
                    animation: integratedMachine 2s infinite linear alternate;

                    &::after {
                        content: "";
                        .box_border();
                    }

                    .easy_upload_inner {
                        width: 128px;
                        height: 128px;
                        border-radius: 50%;
                        background: linear-gradient(-36deg, #1583FF 0%, #4CA1FE 100%);
                        font-weight: normal;
                        font-size: 30px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 128px;
                    }
                }

                .expand {
                    left: 200px;
                    top: 390px;
                    .box();
                    padding: 15px;



                    &::after {
                        content: "";
                        .box_border();
                    }

                    .expand_inner {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: linear-gradient(-36deg, #1583FF 0%, #4CA1FE 100%);
                        font-weight: normal;
                        font-size: 15px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .concurrent {
                    left: 220px;
                    bottom: 220px;
                    .box();
                    padding: 15px;

                    &::after {
                        content: "";
                        .box_border();
                    }

                    .concurrent_inner {
                        width: 103px;
                        height: 103px;
                        border-radius: 50%;
                        background: linear-gradient(-36deg, #1583FF 0%, #4CA1FE 100%);
                        font-weight: normal;
                        font-size: 20px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .low_latency {
                    left: 570px;
                    bottom: 230px;
                    .box();
                    padding: 15px;


                    &::after {
                        content: "";
                        .box_border();
                    }

                    .low_latency_inner {
                        width: 59px;
                        height: 59px;
                        border-radius: 50%;
                        background: linear-gradient(-36deg, #1583FF 0%, #4CA1FE 100%);
                        font-weight: normal;
                        font-size: 15px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .security {
                    right: 112px;
                    bottom: 261px;
                    .box();

                    &::after {
                        content: "";
                        .box_border();
                    }

                    .security_inner {
                        width: 128px;
                        height: 128px;
                        border-radius: 50%;
                        background: linear-gradient(-36deg, #1583FF 0%, #4CA1FE 100%);
                        font-weight: normal;
                        font-size: 30px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 128px;
                    }
                }

                .furnishings {
                    right: 280px;
                    top: 230px;
                    .box();

                    &::after {
                        content: "";
                        .box_border();
                    }

                    .furnishings_inner {
                        width: 108px;
                        height: 108px;
                        border-radius: 50%;
                        background: linear-gradient(-36deg, #1583FF 0%, #4CA1FE 100%);
                        font-weight: normal;
                        font-size: 30px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .compatible {
                    right: 20px;
                    top: 300px;
                    .box();
                    padding: 13px;

                    &::after {
                        content: "";
                        .box_border();
                    }

                    .compatible_inner {
                        width: 62px;
                        height: 62px;
                        border-radius: 50%;
                        background: linear-gradient(-36deg, #1583FF 0%, #4CA1FE 100%);
                        font-weight: normal;
                        font-size: 15px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .high_power {
                    right: 330px;
                    bottom: 320px;
                    .box();
                    padding: 15px;

                    &::after {
                        content: "";
                        .box_border();

                    }

                    .high_power_inner {
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        background: linear-gradient(-36deg, #1583FF 0%, #4CA1FE 100%);
                        font-weight: normal;
                        font-size: 18px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                // :global .ant-popover-inner {
                //     border: 1px solid red;
                // }

            }
        }
    }

    // 应用案例
    .application_case {
        // height: 500px;
        padding-bottom: 100px;
        box-sizing: border-box;

        .application_list {
            height: 80px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .case_title {
                font-weight: normal;
                font-size: 24px;
                color: #000000;
                margin: 0 5rem;
                line-height: 35px;
                cursor: pointer;
            }

            .case_active {
                color: #0078FF;
                border-bottom: 2px solid #0078FF;

            }
        }

        .carousel_wrap {
            padding: 0 250px;
            box-sizing: border-box;
            position: relative;


            .carousel_index {
                height: 412px;
                background: #364d79;

            }

            .arrow_box {
                width: 53px;
                height: 53px;
                position: absolute;
                border-radius: 50%;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                color: #0078FF;
                user-select: none;
            }

            .hover_box {
                background: #087DFF;
                color: #FFFFFF;
                border: 2px solid #FFFFFF;
                cursor: pointer;
                transition: all 0.5s;
            }

            .arrow_left {
                .arrow_box();
                left: 100px;
                background: #FFFFFE;
                border: 2px solid #F1F2F7;

                &:hover {
                    .hover_box();
                }
            }

            .arrow_right {
                .arrow_box();
                right: 100px;
                background: #FFFFFE;
                border: 2px solid #F1F2F7;

                &:hover {
                    .hover_box();
                }
            }

            .ant-carousel .slick-dots {
                display: none !important;
            }
        }
    }
}

@keyframes gradient {
    from {
        opacity: 0;
        // transform: translateY(-30px);
        transform: translateX(-10px);
    }

    to {
        opacity: 1;
        // transform: translateY(0);
        transform: translateX(0);
    }
}

@keyframes machineRoomMove {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes integratedMachine {
    from {
        opacity: 0.9;
        transform: scale(1);
    }

    to {
        opacity: 1;
        transform: scale(1.08);
    }
}