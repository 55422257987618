
// *{
//   scroll-behavior: auto;
// }

body {
  font-family: 'PuHuiTi';
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track-piece {
  /* //   background-color: rgba(0, 0, 0, 0.2); */
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 10px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(125, 125, 125, 0.9);
}


.fade-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.fade-enter-active {
  opacity: 1;
  // transition: opacity 0.3s , transform 0.3s;
  transition: all 0.3s;
  transform: translateX(0px);
}

.fade-exit {
  opacity: 1;
  transform: translateX(0px);
}

.fade-exit-active {
  opacity: 0;
  // transition: opacity 300ms;
  transition: all 0.3s;
  transform: translateX(20px);
}