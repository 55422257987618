.illustrations {
    height: 751px;
    margin-bottom: 100px;

}
.article_wrap {
    padding: 0 200px;
    box-sizing: border-box;
    position: relative;

    .breadcrumb {
        position: absolute;
        top: -50px;
    }
}