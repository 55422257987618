.hot_articles {
    margin-bottom: 67px;

    .hot_title {
        font-weight: normal;
        font-size: 19px;
        color: #000000;
        padding-left: 10px;
        position: relative;
        margin-bottom: 30px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 18px;
            background: #0B5CFF;
        }

    }

    .hot_list_wrap {

        height: 400px;
        display: grid;
        grid-template-columns: repeat(3, 30%);
        justify-content: space-between;

        .hot_index {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 40px;
            box-sizing: border-box;
            border-bottom: 1px solid #E6E6E6;
            cursor: pointer;

            .hot_img {
                width: 100%;
                height: 216px;
            }

            .hot_article_title {
                font-weight: normal;
                font-size: 18px;
                color: #666666;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .hot_article {
                font-weight: normal;
                font-size: 15px;
                color: #828282;
                line-height: 20px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
}