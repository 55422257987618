.illustrations {
    height: 749px;
}

.about_content {
    height: 3125px;
    background: url('../../../public/img/about_background.jpg') no-repeat;
    background-size: 100% 100%;
    padding: 100px 300px 250px;
    box-sizing: border-box;

    .about_title {
        font-weight: normal;
        font-size: 40px;
        color: #000000;
        margin-bottom: 100px;
        text-align: center;
    }
}