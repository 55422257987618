.title_box {
    border: 1px solid transparent;

    // overflow: hidden;
    .title {
        height: 50px;
        font-size: 40px;
        color: #333333;
        line-height: 40px;
        margin-top: 120px;
        margin-bottom: 80px;
        text-align: center;
        font-weight: normal;
        letter-spacing: 3px;
        position: relative;

        .title_inner {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transition: all 0.3s;
            animation: titleMotion 0.5s linear;

        }

        .motion {
            animation: titleMotion 1s linear;
        }
    }
}


@keyframes titleMotion {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }

}


// .fade_enter {
//     opacity: 0;
//     // transform: translateX(100%);
//     transform: translateY(100px);
// }

// .fade-enter-active {
//     opacity: 1;
//     // transform: translateX(0);
//     transform: translateY(0);

//     transition: all 0.5s;
// }

// .fade_enter_done {
//     opacity: 1;
//     // transform: translateX(0);
//     transform: translateY(0);
//     transition: all 0.5s;
// }

// .fade-exit {
//     opacity: 1;
//     transform: translateX(0);
// }

// .fade-exit-active {
//     opacity: 0;
//     transform: translateX(100%);
//     transition: all 0.5s;
// }

// .fade-exit-done {
//     opacity: 0;
//     transform: translateX(100%);
//     transition: all 0.5s;
// }


// .fade-enter {
//     opacity: 0;
//     transform: translateX(100%);
// }

// .fade-enter-active {
//     opacity: 1;
//     transform: translateX(0);
//     transition: all 0.5s;
// }

// .fade-enter-done {
//     opacity: 1;
//     transform: translateX(0);
//     transition: all 0.5s;
// }

// .fade-exit {
//     opacity: 1;
//     transform: translateX(0);
// }

// .fade-exit-active {
//     opacity: 0;
//     transform: translateX(100%);
//     transition: all 0.5s;
// }

// .fade-exit-done {
//     opacity: 0;
//     transform: translateX(100%);
//     transition: all 0.5s;
// }