:global .nav {
 
    :global .ant-menu {
        border: none !important;
        background: transparent !important;
    }

    .ant-menu-title-content {
        font-size: 16px;
    }
}