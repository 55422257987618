.illustrations {
    height: 760px;
    position: relative;

    .box_background () {
        background: url('../../../public/img/bubble_icon.png') no-repeat;
        background-size: 100% 100%;
        animation: integratedMachine 2s infinite linear alternate;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bubble_1 {
        width: 254px;
        height: 238px;
        position: absolute;
        bottom: 40px;
        left: 43vw;
        .box_background();
        animation-delay: 0s;
    }

    .bubble_2 {
        width: 141px;
        height: 132px;
        position: absolute;
        right: 20vw;
        bottom: 50px;
        .box_background();
        animation-delay: 0.8s;
    }

    .bubble_3 {
        width: 181px;
        height: 169px;
        position: absolute;
        top: 20vh;
        right: 12vw;
        .box_background();

        animation-delay: 1.1s;

    }

    .bubble_4 {
        width: 254px;
        height: 238px;
        position: absolute;
        top: 10vh;
        right: 30vw;
        .box_background();
        animation-delay: 1.5s;

    }
}

// 产品推荐
.product_recommendation {
    margin-bottom: 100px;

    .series_1 {
        height: 580px;
        position: relative;


        .series_1_box {
            width: 30vw;
            height: 470px;
            background: #55B7FF;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        .series_1_wrap {
            height: 470px;
            position: absolute;
            left: 214px;
            bottom: 0;
            z-index: 10;
            display: flex;


            .series_img {
                width: 33vw;
                height: 100%;
                position: relative;

                .series_img_position {
                    width: 33vw;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .series_img_mobile {
                    animation: leftEnter 0.5s linear;
                }

            }
        }
    }

    .series_2 {
        height: 580px;
        position: relative;


        .series_2_box {
            width: 30vw;
            height: 470px;
            background: #AEDBFD;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }

        .series_2_wrap {
            height: 470px;
            position: absolute;
            right: 200px;
            bottom: 0;
            z-index: 10;
            display: flex;

            .series_2_img {
                width: 33vw;
                min-width: 500px;
                height: 470px;
                flex-shrink: 0;
                position: relative;

                .series_2_img_inner {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: none;
                }

                .series2_img_moblie {
                    display: block;
                    animation: rightEnter 1s linear;
                }
            }
        }
    }

    .series_content_wrap {
        width: 40vw;
        height: 100%;
        position: relative;

        .series_content_inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 36px;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 0;

            .series_hover:hover {
                text-decoration: underline;
                color: #1677ff;
                cursor: pointer;
            }

            .series_h1 {
                font-weight: normal;
                font-size: 48px;
                color: #4E4E4F;
                white-space: nowrap;
                margin-bottom: 13px;
                user-select: none;
            }

            .series_h2 {
                font-weight: normal;
                font-size: 32px;
                color: #4E4E4F;
                user-select: none;
            }

            .series_describe {
                font-weight: normal;
                font-size: 24px;
                color: #4B5562;
                line-height: 40px;
            }

            .base_btn {
                width: 190px;
                height: 50px;
                // border: 1px solid #6C6C6C;
                font-weight: normal;
                font-size: 19px;
                color: #6C6C6C;
                line-height: 22px;
                border-radius: 0;
            }
        }

    }
}

// 新闻动态
.news_wrap {
    height: 680px;
    position: relative;

    &::after {
        content: "";
        width: 80vw;
        height: 753px;
        position: absolute;
        right: 0;
        top: 0;
        // z-index: -1;
        // background: #558dec;
        background: url('../../../public/img/index_dynamic.png') no-repeat;
        background-size: 100% 100%;
    }

    .news_position {
        // width: 80vw;
        height: 753px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index: 2;


        .news_content {
            width: 50vw;
            height: 560px;
            background: #fff;
            box-shadow: 0px 1px 38px 0px rgba(170, 196, 250, 0.54);
            border-radius: 10px;
            padding: 22px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .news_img {
                width: 100%;
                height: 77%;
                margin-bottom: 20px;
            }

            .news_content_title {
                font-weight: normal;
                font-size: 32px;
                color: #000000;
                margin-bottom: 10px;
            }

            .new_content_text {
                font-weight: normal;
                font-size: 18px;
                color: #949494;
                line-height: 23px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .news_list {
            width: 36vw;
            height: 100%;
            margin-left: 100px;
            flex-shrink: 0;
            padding: 85px 50px 85px 0;
            box-sizing: border-box;
            position: relative;

            .enlarged {
                transform: scale(1.1);
            }

            .news_index {
                width: 100%;
                height: 115px;
                box-sizing: border-box;
                margin-bottom: 30px;
                border-bottom: 1px solid #E0E0E0;
                display: flex;
                cursor: pointer;


                .news_timer {
                    width: 94px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;

                    &::after {
                        content: "";
                        width: 2px;
                        height: 58px;
                        background: rgba(216, 216, 216, 0.8);
                        position: absolute;
                        right: 0;
                        top: 0;
                        border-radius: 5px;
                    }

                    .year {
                        font-weight: normal;
                        font-size: 24px;
                        color: #FFFFFF;
                        margin-bottom: 10px;
                        // line-height: 40px;
                    }

                    .date {
                        font-size: 20px;
                        color: #FFFFFF;
                        font-weight: normal;
                    }
                }

                .news_profile {
                    width: 85%;
                    padding-left: 24px;
                    box-sizing: border-box;

                    .profile_title {
                        font-weight: normal;
                        font-size: 23px;
                        color: #FFFFFF;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 9px;
                    }

                    .profile_content {
                        font-weight: normal;
                        font-size: 20px;
                        color: #FFFFFF;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .more {
                font-weight: normal;
                font-size: 21px;
                color: #FFFFFF;
                position: absolute;
                right: 60px;
                cursor: pointer;
            }
        }
    }
}

// 合作伙伴
.partners_wrap {
    // height: 700px;
    padding: 0 0 60px 0;

    .carousel_index {
        width: 60vw;
        margin: 0 auto 0;
        padding-bottom: 80px;
        display: grid;
        grid-template-columns: repeat(5, 19%);
        grid-row-gap: 10px;
        grid-column-gap: 10px;

        .box_index {
            // width: calc(263px - 10px);
            // width: 10vw;
            height: 178px;
            background: #FFFFFF;
            box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            // margin: 5px;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // justify-content: space-between;
            // padding: 13px 0;
            box-sizing: border-box;
            display: inline-block;


            .box_img {
                width: 138px;
                height: 138px;
                box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.15);
                border-radius: 50%;
                overflow: hidden;
                margin: auto;
            }

            .box_text {
                font-weight: normal;
                font-size: 17px;
                color: #000000;
                text-align: center;
            }
        }
    }

    :global .ant-carousel .slick-dots li {
        width: 44px;
        height: 6px;

        button {
            height: 100%;
            background: #D2D2D2;
        }
    }

    :global .slick-dots li.slick-active button {
        background: #0B5CFF;
    }

}


// 联系我们
.consult_wrap {
    width: 100%;
    height: 700px;
    display: flex;

    .consult_title() {
        width: fit-content;
        font-weight: normal;
        font-size: 31px;
        color: #474747;
        padding-bottom: 10px;
        border-bottom: 2px solid #D9D9D9;
        margin-bottom: 20px;
    }

    .consult_left {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;



        .consult_left_box {
            margin-bottom: 60px;
            padding-left: 30%;


            .consult_left_title {
                .consult_title();
            }

            .consult_left_text {
                font-weight: normal;
                font-size: 20px;
                color: #333333;
                line-height: 28px;
            }

            .consult_code {
                width: 119px;
                padding: 3px;

                .code {
                    width: 100%;
                    height: 119px;
                    margin-bottom: 5px;
                }

                .code_text {
                    font-weight: normal;
                    font-size: 18px;
                    color: #000000;
                    text-align: center;
                }
            }
        }
    }

    .consult_right {
        flex-grow: 1;
        height: 100%;


        .consult_right_title {
            .consult_title();
        }

        .consult_right_address {
            font-weight: normal;
            font-size: 20px;
            color: #333333;
            margin-bottom: 54px;
        }

        .address {
            width: 45vw;
            height: 483px;
            background: #FFFFFF;
            border-radius: 20px;
            border: 3px solid #EBEBEB;
            position: relative;
            overflow: hidden;
            // &::after {
            //     content: "";
            //     width: 100%;
            //     height: 100%;
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     z-index: 10000;
            // }
        }
    }
}



@keyframes leftEnter {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes rightEnter {
    from {
        opacity: 0;
        transform: translateX(100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes integratedMachine {
    from {
        opacity: 0.9;
        transform: scale(1);
    }

    to {
        opacity: 1;
        transform: scale(1.1);
    }
}