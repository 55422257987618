.company_wrap {
    position: relative;

    .illustrations {
        height: 749px;
    }

    .company_details {
        width: 80%;
        // height: 1000px;
        position: absolute;
        top: 580px;
        left: 50%;
        transform: translateX(-50%);
        padding: 80px 40px 20px;
        box-sizing: border-box;
        box-shadow: 0px 0px 14px 2px rgba(156, 177, 204, 0.28);
        background-color: #fff;
        border-radius: 5px;

        .company_icon {
            width: 146px;
            height: 136px;
            position: absolute;
            top: -80px;
            left: 100px;
            border-radius: 5px;
            overflow: hidden;
        }

        .company_inner {
            width: 100%;
            height: 100%;

            .company_title {
                font-weight: normal;
                font-size: 45px;
                color: #333333;
                text-align: center;
                // margin-bottom: 54px;
                margin-bottom: 25px;
            }

            .gather {
                // text-align: center;
                font-weight: normal;
                font-size: 25px;
                color: #333333;
                margin-bottom: 10px;
                line-height: 45px;
                text-indent: 3rem;
            }

            // .gather {
            //     text-align: center;
            //     font-weight: normal;
            //     font-size: 28px;
            //     color: #333333;
            //     margin-bottom: 60px;
            //     line-height: 45px;
            // }

        }
    }

    .company_address {
        // padding-top: 800px;
        padding-top: 430px;
        box-sizing: border-box;

        .company_address_inner {
            height: 1100px;
            padding: 0 200px;
            box-sizing: border-box;
        }
    }
}