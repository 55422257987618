.skeleton {
    .top_nav {
        // height: 80px;
        padding-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        top: 0;
        z-index: 99999;
        background: rgba( 255,255,255 , .8);
        backdrop-filter: blur(10px);
        .nav_logo {
            width: 200px;
            height: 58px;
            margin-right: 80px;
            margin-bottom: 20px;
        }

        .nav {
            width: 850px;
            height: 51px;
        }
    }

    .bottom_nav {
        height: 44px;
        background: #2562DF;
        font-weight: normal;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 44px;
        text-align: center;
    }
}