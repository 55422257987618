.article_title {
    width: 100%;
    height: 120px;
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    justify-content: space-around;
    margin-bottom: 51px;
    border-bottom: 1px solid rgba(102, 102, 102 , 0.3);

    .title {
        font-weight: normal;
        font-size: 32px;
        color: #333333;
    }

    .released {
        font-weight: normal;
        font-size: 16px;
        color: #838282;

        .released_timer {
            margin: 0 15px 0 5px;
            position: relative;


            // &::after {
            //     content: "";
            //     width: 1px;
            //     height: 14px;
            //     background: #666666;
            //     position: absolute;
            //     right: -8px;
            //     top: 50%;
            //     transform: translateY(-50%);
            //     opacity: 0.7;
            // }
        }
    }

}