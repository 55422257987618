.illustrations {
    height: 760px;
    margin-bottom: 112px;
}

.agents_title {
    text-align: center;

    .title {
        font-weight: normal;
        font-size: 40px;
        color: #000000;
        margin-bottom: 37px;
    }

    .profile {
        font-weight: normal;
        font-size: 24px;
        color: #000000;
        margin-bottom: 109px;
    }
}

.agents_content {
    padding: 0 400px 30px;
    box-sizing: border-box;

    .upload_wrap {
        position: relative;
        .upload_tips {
            color: #a19898;
            position: absolute;
            top: 5px;
            left: 160px;
        }
    }
}

.space_wrap {
    width: 100%;
    display: flex;
    justify-content: center;

    .base_btn {
        width: 100px;
    }
}