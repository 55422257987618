.article_content {
    .article_title {

        width: fit-content;
        color: #0d50c9;
        font-size: 20px;
        padding: 10px 20px;
        // background: url('../../../public/img/article_title.png') no-repeat;
        background: #ebf2fa;
        border-radius: 5px;
        // background-size: 100% 100%;
        // background-position: center center;
        margin: 50px auto;
        text-align: center;
        line-height: 25px;
    }

    .subtitle_title {
        width: fit-content;
        background: url('../../../public/img/subtitle_title.png') no-repeat;
        background-size: 100% 100%;
        padding: 20px 60px 20px 30px;
        color: #fff;
        // margin: auto;
    }

    .list_ul {
        width: fit-content;
        padding-left: 35px;
        box-sizing: border-box;
        color: #0d50c9;
        margin: 20px 0;
        // margin: 20px auto;
        .list_index {
            width: fit-content;
            margin-bottom: 25px;
            font-size: 20px;
            position: relative;
            // text-align: center;

            &::before {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                background: #0d50c9;
                border-radius: 50%;
                left: -15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .content {
        font-weight: 400;
        font-size: 16px;
        color: #797979;
        text-indent: 2em;
        line-height: 30px;
        margin-bottom: 40px;
        p {
            margin-bottom: 15px;
        }
    }

    .content_img {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tips_text{
        color: #b2b2b2;
        text-align: center;
        margin-bottom: 20px;
    }
}