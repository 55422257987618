.chip_title {
    height: 41px;
    padding-left: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .title {
        font-weight: normal;
        font-size: 36px;
        color: #000000;
        flex-shrink: 0;
        margin-right: 25px;


        &::before {
            content: "";
            width: 3px;
            height: 85%;
            background: #206AFF;
            border-radius: 5px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .line {
        width: 90%;
        border-bottom: 2px solid #EDEDED;
    }

}
.chip_wrap {
    margin: 50px 20px;
    // padding: 0 33px;
    box-sizing: border-box;
    line-height: 38px;
 
}